<template>
  <div class="landing desktop">
    <div class="topper">
      <div class="i_01 col"></div>
      <div class="i_02 col"></div>
      <div class="i_03 col"></div>
    </div>
    <MenuPage />
    <div class="footer">
      <div v-for="(image, index) in desktopImages" :key="index" :class="`i_0${index + 4} col`" :style="{ backgroundImage: `url(${image})` }"></div>
    </div>
  </div>

  <div class="landing mobile">
    <div class="topper">
      <div class="i_01 col"></div>
      <div class="i_02 col"></div>
      <div class="i_03 col"></div>
    </div>
    <MenuPage />
    <div class="footer">
      <div v-for="(image, index) in mobileImages" :key="index" :class="`i_0${index + 4} col`" :style="{ backgroundImage: `url(${image})` }"></div>
    </div>
  </div>
</template>

<script>
import MenuPage from './MenuPage.vue'
  
export default {
  name: 'LandingPage',
  components: {
    MenuPage
  },
  data() {
    return {
      allImages: [
        require('@/assets/img/Footer01.png'),
        require('@/assets/img/Footer02.png'),
        require('@/assets/img/Footer03.png'),
        require('@/assets/img/Footer04.png'),
        require('@/assets/img/Footer05.png'),
        require('@/assets/img/Footer06.png'),
        require('@/assets/img/Footer07.png')
      ],
      desktopImages: [],
      mobileImages: []
    };
  },
  methods: {
    loadImages() {
      this.mobileImages  = this.shuffleArray(this.allImages).slice(0, 3);
      this.desktopImages = this.shuffleArray(this.allImages).slice(0, 5);
    },
    shuffleArray(array) {
      let randomArray = array.slice();
      for (let i = randomArray.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [randomArray[i], randomArray[randomIndex]] = [randomArray[randomIndex], randomArray[i]];
      }
      return randomArray;
    }
  },
  mounted() {
    this.loadImages();
  }
};
</script>