import httpCommon from '../../api/http-common';
import authHeader from '../auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/menu';

class MenuService {
  async getActive() {
    return await httpCommon.get(
      API_URL + '/active', { headers: authHeader(),  })
        .then( res => {
          return res.data;
        })
        .catch(error => {
          console.log(error);
          return []
        });
  }

  async getActiveAdmin() {
    return await httpCommon.get(
      API_URL + '/active/admin', { headers: authHeader(),  })
        .then( res => {
          return res.data;
        })
        .catch(error => {
          console.log(error);
          return []
        });
  }

  async save(data) {
    return await httpCommon.post(API_URL + '/', data, { headers: authHeader() });
  }

}

export default new MenuService();